/*******************************
Mascot Chat Theme overrides
*******************************/

// Default Styles - applies to "light" theme
.mascot-chat-panel {
  color: $color-text;

  // Set theme font
  .message-content,
  .message-content .wmde-markdown {
    background-color: transparent;
    font-family: inherit !important;
  }

  // .chat-header {
  //   @include break-max($break-sm) {
  //     border-bottom: .5px solid $color-border;
  //     box-shadow: 0 10px 18px -8px rgba(0,0,0,0.10);
  //   }
  // }

  .chat-body {
    .messages-wrapper .message {
      pre code {
        color: white;
      }

      @media (prefers-color-scheme: light) {
        pre code {
          color: black;
        }
      }

      p code {
        color: $color-text;
      }

      .wmde-markdown {
        hr {
          border-bottom: 1px solid $color-border;
        }
        
        table {
          th,
          td {
            border-color: $color-border;
            border-radius: 0 !important;
          }
          th {
            background-color: $color-off-white;
          }
          tr {
            background-color: transparent;
          }
        }
      }

      &.user {
        border: 1px solid $color-positive;
        background-color: white;
      }
    }

    .new-message {
      background-color: white;
      box-shadow: 0 -10px 18px -8px rgba(0, 0, 0, 0.12);
      border-top: .5px solid $color-border;
      transition: border-color 400ms;

      @include break-min($break-sm + 1) {
        box-shadow: 0 10px 18px -8px rgba(0, 0, 0, 0.12);
        border: 1px solid $color-border;
      }

      .new-message-field {
        background-color: transparent;
      }

      .btn-attachment {
        &::after {
          border-right: 1px solid $color-border;
        }
      }
    }

    .attachment-item {
      background-color: white;
      border: 1px solid $color-border;
      
      .remove-attachment {
        background-color: $color-light-grey;
        border: 1px solid $color-border;
        
        > i {
          color: $color-text;
        }
      }
    }
  }
}

// Set light theme background
body:has(.light-theme) {
  // fill main chat page background
  .mascot-chat,
  // fill chat panel for covering sidebar when closed
  .mascot-chat-panel {
    background-color: $color-off-white;
  }
}

// Set dark theme background
body:has(.dark-theme) {
  // fill main chat page background
  .mascot-chat,
  // fill chat panel for covering sidebar when closed
  .mascot-chat-panel {
    background-color: $color-bg-dark;
  }
}

// Set dark theme chat styles
.mascot-chat.dark-theme {
  .mascot-chat-panel {
    button {
      color: white;
      background-color: $color-button-dark;

      &:hover {
        background-color: color.adjust($color-dark-blue, $lightness: 20%, $saturation: -30%);
      }
    }

    .loader {
      border-color: $color-border-dark;
      border-top-color: $color-positive;
    }
    
    .icon-btn {
      > i {
        color: $color-link-light
      }
  
      &:hover > i {
        color: white;
      }
    }

    .popover,
    .popover.has-caret::after {
      background-color: $color-dark-blue;
      border-color: $color-border-dark;
    }
    
    .popover {
      color: white;

      .popover-item {
        color: white;
        border-color: $color-border-dark;

        > i {
          color: $color-link-light
        }

        &:hover,
        &:hover > i {
          color: $color-positive;
        }
      }
    }

    .meta {
      color: rgba(white, .7);
    }

    .not-ready {
      > img {
        filter: invert(100%);
      }
      > * {
        color: white;
      }
    }

    .chat-header {
      // @include break-max($break-sm) {
      //   border-bottom-color: $color-border-dark;
      //   box-shadow: 0 20px 20px 0 rgba(black, .51);
      // }

      h6,
      h2 {
        color: white;
      }

      .chat-header-middle {
        .chat-title .edit-title > input {
          border-color: $color-border-dark;
          color: white;
        }
        
        i {
          color: white;
        }
      }
    }

    .chat-body {
      .messages-wrapper {
        p.empty-message,
        p code {
          color: white;
        }

        .chat-empty {
          > p {
            color: white;
          }
        }

        .message {
          .message-header {
            h5 {
              color: white;
            }
            .meta {
              color: rgba(white, .5)
            }
          }

          .message-actions {
            .icon-btn {
              &.active {
                background-color: $color-button-dark;

                > i {
                  color: white;
                }
              }
            }
          }

          .wmde-markdown {
            h1, h2, h3, h4, h5, h6,
            p {
              color: inherit;
            }

            pre {
              background-color: black;
            }

            hr {
              background-color: transparent;
              border-bottom: 1px solid $color-border-dark;
            }

            table {
              th,
              td {
                border-color: $color-border-dark;
              }
              th {
                background-color: $color-bg-dark;
              }
            }
          }

          &.user {
            background-color: $color-dark-blue;
            border: 1px solid $color-positive;
          }
        }
      }

      .new-message {
        background-color: $color-bg-dark;
        border-color: $color-border-dark;
        box-shadow: 0 -20px 20px 0 rgba(black, .51);

        @include break-min($break-sm + 1) {
          background-color: $color-dark-blue;
          box-shadow: 0 10px 18px -5px rgba(black, 1);
        }

        .btn-attachment {
          &::after {
            border-color: $color-border-dark;
          }
        }

        .new-message-field {
          color: white;

          &::placeholder {
            color: rgba(white, 0.5);
          }
        }
      }

      .attachment-item {
        background-color: rgba(black, .50);
        border-color: $color-border-dark;

        h6 {
          color: white;
        }
        
        .remove-attachment {
          background-color: $color-dark-blue;
          border-color: $color-border-dark;
          
          > i {
            color: white;
          }
        }
      }

      .responding {
        background-color: $color-bg-dark;
        border-color: $color-border-dark;

        .responding-dots {
          > span {
            background-color: white;
          }
        }
        
        .meta {
          color: white;
        }
      }

      .wiseox-badge {
        .logo > span {
          color: white;
        }
        
        .wiseox-badge-links > a {
          color: $color-link-light;
        }
      }
    }
  }
}
