/*******************************
Chat Body
*******************************/

.chat-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  position: relative;
  z-index: 1;

  @include break-min($break-sm + 1) {
    padding-bottom: 30px;
  }

  // Global item within chat body
  .chat-topics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > button {
      margin: 0 8px 8px 0;
      border-radius: 16px;
    }
  }

  // Global item within chat body
  .attachment-wrapper {
    margin: 5px 15px 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.message-attachments {
      margin: 0 0 -10px;
    }
  
    .attachment-item {
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 0 10px 10px 0;
      height: 48px;
      padding: 0 10px;      
      position: relative;
        
      > img,
      .loader {
        margin-right: 10px;
      }

      > img {
        display: block;
        width: 28px;
        height: auto;
      }

      .meta {
        @include truncate();
        max-width: 180px;
      }
  
      .remove-attachment {
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -7px;
        right: -7px;

        > i {
          font-size: 14px;
          margin: auto;
        }
      }
    }
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    flex: 1;
    overflow: auto;
    padding-top: calc($chat-header-height - 10px);

    @include break-min($break-lrg + 1) {
      padding-top: $chat-header-height;
    }

    .chat-empty {
      margin: auto;
      width: 100%;
      max-width: 640px;
      padding: 30px;

      > h1 {
        margin: 0 0 30px;
      }

      > p {
        margin: 0 0 15px;
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      max-width: $chat-max-width;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      flex: 1;
      animation: messageIn 300ms ease-out;

      @include break-min($break-xs + 1) {
        padding: 15px 15px 30px;
      }

      @include break-min($break-sm + 1) {
        padding: 15px 30px 30px;
      }

      @include break-min($break-lrg + 1) {
        padding: 15px 40px 30px;
      }
    }

    .message {
      border-radius: 24px;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      margin-bottom: 10px;
      position: relative;
      padding: 12px 15px;
      min-height: 56px;

      @include break-min($break-sm + 1) {
        padding: 18px;
      }

      &.user {
        margin-bottom: 2px;

        &::after {
          display: block;
          border-radius: 50%;
          content: '';
          background-color: inherit;
          border: 1px solid transparent;
          border-color: inherit;
          position: absolute;
          width: 10px;
          height: 10px;
          right: -6px;
          bottom: 0;
        }

        &.new-message-animation:nth-last-child(2) {
          animation: messageIn 300ms ease-out;
        }

        .message-content {
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }

      &.assistant {
        margin-bottom: 5px;

        @include break-min($break-sm + 1) {
          margin-left: 20px;
        }

        @include break-max($break-sm) {
          padding-bottom: 48px;
        }

        &.streaming-message {
          animation: messageIn 300ms ease-out;
        }
        
        .message-content {
          .message-function-execution {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .loader {
              margin: 0 0 0 5px;
            }
          }

          &:not(.not-hoverable) .wmde-markdown {
            > *:not(pre):not(table):not(ol):not(ul):hover,
            > pre .code-line:hover,
            table td:hover,
            table th:hover,
            ol li:hover,
            ul li:hover {
              background-color: rgba($color-medium-grey, 0.35);
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }

      .message-header {
        display: flex;
        align-items: center;

        .mascot-image,
        .user-avatar {
          display: block;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          margin-right: 12px;

          @include break-max($break-sm) {
            position: absolute;
            top: 11px;
            left: 15px;
          }

          @include break-min($break-sm + 1) {
            width: 38px;
            height: 38px;
          }
        }

        .user-avatar {
          background-color: $color-positive;
          display: flex;
          align-items: center;
          justify-content: center;

          > h3 {
            color: white;
            margin: 0;
            font-weight: $semi-bold;
            text-align: center;
            line-height: 32px;
            font-size: 16px;

            @include break-min($break-sm + 1) {
              line-height: 38px;
              font-size: 21px;
            }
          }
        }

        .message-header-title {
          > h5 {
            margin: 0;
            font-size: 16px;
            line-height: 20px;
          }

          .meta {
            display: block;
            margin-top: 2px;
          }

          @include break-max($break-sm) {
            display: none;
          }
        }

        .message-actions {
          display: flex;
          align-items: center;
          margin: 0 0 auto auto;

          .icon-btn {
            margin-left: 8px;
            border-radius: 4px;
            padding: 5px;

            &.active {
              background-color: color.adjust($color-light-grey, $lightness: -10%);

              > i {
                color: $color-text;
              }
            }

            > i {
              font-size: 16px;

              &.icon-thumbs-up-solid {
                color: $color-rank-positive;
              }
              &.icon-thumbs-down-solid {
                color: $color-rank-negative;
              }
            }
          }

          @include break-max($break-sm) {
            position: absolute;
            left: 52px;
            bottom: 10px;

            .icon-btn {
              margin: 0 8px 0 0;
            }
          }
        }
      }

      .message-content {
        flex: 1;
        margin: 15px 0 0;

        @include break-max($break-sm) {
          margin: 2px 0 0 42px;
        }

        p {
          margin: 15px 0;
          
          &:first-child {
            margin-top: 0;
          }
  
          &:last-child {
            margin-bottom: 0;
          }
        }
        
        ul, ol {
          padding-left: 20px;

          p, li {
            margin: 0 0 10px;
          }
        }

        .wmde-markdown {
          margin: 0;
        }
          
        @include break-max($break-xs) {
          p,
          .wmde-markdown {
            font-size: 14px;
          }
        }
      }
    }
  }

  .new-message {
    border-radius: 18px 18px 0 0;
    position: relative;
    width: 100%;
    max-width: $chat-max-width;
    margin: auto;
    transition: border-color 400ms;

    @include break-min($break-sm + 1) {
      border-radius: 24px;
      width: calc(100% - 30px);
    }

    @include break-min($break-lrg + 1) {
      width: calc(100% - 60px);
    }

    .new-message-field {
      background-color: transparent;
      border-radius: inherit;
      border: 0;
      display: block;
      padding: 20px 78px 20px 65px;
      min-height: 0;
      margin: 0;
      min-height: 64px;
      position: relative;
      z-index: 1;

      &:focus {
        box-shadow: none;
      }
    }

    .btn-send-message {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      top: 13px;
      right: 12px;
      z-index: 2;

      @include break-min($break-sm + 1) {
        width: 40px;
        height: 40px;
        top: 12px;
      }

      > i {
        color: white;
        display: block;
        font-size: 18px;
      }
    }

    .btn-attachment {
      position: absolute;
      margin: 0;
      padding: 0;
      top: 22px;
      left: 20px;
      z-index: 2;

      &::after {
        display: block;
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        right: -13px;
      }
    }

    .chat-topics {
      padding: 0 20px 8px 20px;

      @include break-max($break-sm) {
        max-width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
      }
    }

    .responding {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      border-radius: 12px;
      border: 1px solid $color-border;
      box-shadow: 0 10px 10px -5px rgba(black, 0.15);
      height: 32px;
      margin: 0;
      padding: 0 10px;
      position: absolute;
      bottom: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);
      animation: respondingIn 200ms;
      z-index: 3;

      .responding-dots {
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          background-color: $color-text;
          border-radius: 50%;
          width: 4px;
          height: 4px;
          animation: dots 900ms infinite ease-out;

          &:nth-child(2) {
            margin: 0 4px;
            animation-delay: 300ms;
          }
          &:nth-child(3) {
            animation-delay: 600ms;
          }
        }
      }

      .meta {
        color: $color-text;
        white-space: nowrap;
        font-weight: $medium;
        margin-left: 6px;
      }

      > button {
        border-radius: 7px;
        display: none;
        height: auto;
        position: absolute;
        inset: 5px;
        margin: 0;
      }

      &:hover {
        > button.cancel {
          display: block;
        }
      }
    }
  }

  .wiseox-badge {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-family: $font-family-lexend;
    margin: 15px auto -15px;

    @include break-max($break-sm) {
      display: none;
    }

    .logo {
      display: flex;
      align-items: center;

      > span {
        color: $color-dark-blue;
        font-size: 11px;
        font-weight: $medium;
        letter-spacing: -.2px;
        text-transform: uppercase;
        margin-right: 6px;
      }

      > img {
        display: block;
        height: 16px;
        width: auto;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .wiseox-badge-links {
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 12px;

      > span {
        color: $color-text-light;
        margin: 0 5px;
      }

      > a {
        color: $color-text-light;
        display: block;
      }
    }
  }

  .mascot-wrapper.chat ~ .wiseox-embed-chat {
    display: none;
  }
}