/*******************************
Vars
*******************************/

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

$color-dark-blue: #011f31;
$color-bg-dark: #010C12;
$color-border-dark: #334e59;
$color-positive: #3dbfbf;
$font-family: "Lexend", "Helvetica", sans-serif;

// Loading Spinner
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showMascot {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/*******************************
Embed Chat
*******************************/

.wiseox-embed-chat {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;

  * {
    box-sizing: border-box;
  }

  .wiseox-embed-hidden {
    display: none;
  }

  .wiseox-embed-img {
    cursor: pointer;
    position: relative;
    z-index: 1;

    > img {
      background-color: $color-bg-dark;
      box-shadow: 0 4px 8px -2px rgba(black, 0.8);
      border: 1px solid $color-border-dark;
      padding: 4px;
      border-radius: 50%;
      display: block;
      width: 92px;
      height: 92px;
      transition: transform 200ms;
      transform: scale(1);
      position: relative;
      z-index: 1;
    }

    .wiseox-embed-img-label {
      background-color: $color-positive;
      border-radius: 4px;
      color: black;
      font-size: 12px;
      font-weight: 500;
      font-family: $font-family;
      line-height: 1.3;
      padding: 2px 4px;
      position: absolute;
      z-index: 3;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }

    .loader {
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: rgba($color-border-dark, 0.2);
      border-top-color: $color-dark-blue;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      inset: 0;
      z-index: 2;
      transform: scale(1.2);
      animation: loader 1s linear infinite;

      &.dark {
        border-color: $color-border-dark;
        border-top-color: $color-positive;
      }
    }

    &:hover {
      > img {
        transform: scale(1.2);
      }

      .loader {
        display: none;
      }
    }
  }

  .wiseox-embed-iframe {
    background-color: $color-bg-dark;
    border-radius: 20px;
    box-shadow: 0 15px 30px -2px rgba(black, 0.45);
    border: 1px solid $color-border-dark;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    max-width: 460px;
    max-height: 620px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: inherit;
    overflow: hidden;
    transform-origin: bottom right;
    animation: showMascot 300ms;

    .btn-hide {
      background-color: $color-dark-blue;
      border: 1px solid $color-border-dark;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;
      height: 28px;
      font-family: $font-family;
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      padding: 0 8px;
      position: absolute;
      transition: background-color 0.2s;
      top: 11px;
      right: 15px;
      z-index: 2;

      &:hover {
        background-color: #043f5a;
        border-color: $color-positive;
      }
    }

    > iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
}
