/*******************************
Mascot Header
*******************************/

.mascot-sidebar {
  overflow: hidden;

  .close-sidebar {  
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;

    @include break-min($break-lrg + 1) {
      display: none;
    }

    > i {
      color: white;
      font-size: 20px;
    }
  }

  .mascot-sidebar-content {
    display: flex;
    flex-direction: column;
    padding: 40px 15px 0;
    position: absolute;
    z-index: 2;
    inset: 0;
    overflow: auto;
    padding-bottom: 40px;
  }

  &.bg-empty .mascot-sidebar-bg-wrapper {
    border: .5px solid $color-border-dark;
  }

  .mascot-sidebar-bg-wrapper {
    border-radius: 12px;
    background-color: $color-dark-blue;
    overflow: hidden;
    position: absolute;
    inset: 0;
    z-index: 1;

    .mascot-sidebar-bg {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.6;
      position: absolute;
      z-index: 1;
      inset: 0;

      // Background image hidden on mobile
      @include break-max($break-lrg) {
        display: none;
      }
    }

    // BG image blur
    // update 5/7/2024 Moved blur overlay from mascot-sidebar-content to psuedo element
    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      z-index: 2;

      // Background blur only on larger screens
      @include break-min($break-lrg + 1) {
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
      }
    }
  }

  .icon-btn {
    position: absolute;
    top: 11px;

    > i {
      color: $color-link-light
    }

    &:hover > i {
      color: white;
    }
    
    &.to-home {
      left: 11px;
      text-decoration: none;
    }

    &.share {
      right: 11px;
    }
  }

  .mascot-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .mascot-image-wrapper {
      position: relative;

      .thinking {
        animation: show 700ms;

        .loader {
          border-color: $color-border-dark;
          border-top-color: $color-positive;
          box-shadow: 0 0 15px 0 rgba($color-positive, 0.4);
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          margin: 0;
          position: absolute;
          inset: -4px;
          z-index: 2;
        }
      }

      .mascot-image {
        border-radius: 50%;
        display: block;
        height: 120px;
        width: 120px;
        position: relative;
        z-index: 1;
      }
    }

    .mascot-heading {
      > h2 {
        color: white;
        margin: 20px 0 13px;
        font-weight: $semi-bold;
      }

      > p {
        margin: 0;
      }
    }
  }

  .done-editing {
    margin: 25px auto 15px;
    flex-shrink: 0;
  }

  .mascot-actions {
    margin: 20px 0;

    .admin-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 10px;

      &:not(:empty) {
        margin-bottom: 10px;
      }
      
      .mascot-action {
        flex: 1;
        width: calc(50% - 5px);
      }
    }

    .mascot-action {
      background-color: rgba(white, 0.15);
      border-radius: 12px;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      transform: scale(1);
      transition: background-color 200ms;

      > i {
        color: white;
        font-size: 16px;
        margin-right: 5px;
      }

      &:hover {
        background-color: rgba(white, 0.25);
      }
    }
  }

  .sessions-container {
    margin-right: -15px;
    
    .sessions-list-separator {
      color: rgba(white, 0.65);
      text-transform: uppercase;
      font-weight: $bold;
      font-size: 12px;
      margin: 30px 0 10px;

      &:first-child {
        margin-top: 10px;
      }
    }

    .sessions-list-item {
      padding: 11px 27px 11px 12px;
      border-radius: 8px 0 0 8px;
      position: relative;
      margin-bottom: 5px;

      &.active {
        background-color: rgba(black, .14);

        .session-active {
          background-color: $color-positive;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.empty {
        > span {
          background-color: rgba(white, 0.2);
          border-radius: 8px;
          display: block;
          height: 16px;
          overflow: hidden;
          position: relative;

          &.loading::after {
            animation: sessionListItemLoading 500ms infinite linear;
            animation-direction: alternate;
            background-image: linear-gradient(90deg, rgba(white, 0) 0%, rgba(white, 0.3) 50%, rgba(white, 0) 100%);
            display: block;
            content: "";
            height: inherit;
            width: 80px;
            position: absolute;
            top: 0;
          }
        }
      }

      &:not(.empty) {
        cursor: pointer;

        > span {
          display: block;

          &.meta {
            color: rgba(white, 0.65);
            margin-top: 5px;
          }
        }

        .session-title {
          @include truncate();
          max-width: 100%;
          font-size: 14px;
        }

        &:hover {
          color: $color-positive;
        }
      }
    }
  }

  .edit-nav {
    margin-top: 25px;
    
    > hr {
      border-color: rgba(white, 0.25);
      margin: 15px 0;
    }

    .edit-nav-item {
      border-radius: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: white;
      font-size: 15px;
      padding: 12px;
      margin-bottom: 6px;
      position: relative;

      > * {
        position: relative;
        z-index: 2;
      }

      > i {
        font-size: 20px;
        color: white;
        margin-right: 10px;
      }

      &::after {
        background-color: $color-action;
        border-radius: 12px;
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        transform-origin: left center;
        opacity: 0;
        z-index: 1;
      }

      &:hover {
        background-color: rgba(white, .1);
        text-decoration: none !important;
      }

      &.active {
        pointer-events: none;
        font-weight: $bold;
        color: black !important;

        > i {
          color: inherit !important;
        }

        .editors {
          pointer-events: initial;
        }
     
        &::after {
          transform: scale(1);
          opacity: 1;
          animation: tabActive 200ms ease-out;
        }
      }

      .editors {
        position: absolute;
        right: 10px;

        .editor {
          &:not(:last-child) {
            margin-right: -10px;
          }
        }
      }

      .new {
        background-color: black;
        border-radius: 4px;
        color: $color-action;
        font-size: 11px;
        font-weight: $medium;
        text-transform: uppercase;
        padding: 2px 4px;
        margin-left: auto;
      }
    }
  }
}