/*******************************
Chat Header
*******************************/

.chat-header {
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: $chat-header-height;
  width: 100%;
  position: absolute;
  inset: 0 0 auto;
  z-index: 2;

  button {
    margin: 0;
  }

  @include break-max($break-lrg) {
    flex: 0 0 75px;
    height: calc($chat-header-height - 10px);
  }

  .chat-header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto 0 15px;
    position: relative;
    z-index: 2;

    .mascot-status {
      display: flex;
      align-items: center;
      margin-left: 15px;

      .loader {
        margin: 0;
        width: 16px;
        height: 16px;
      }

      > img {
        display: block;
        width: 16px;
        height: 16px;
      }

      .meta {
        margin: 0 0 0 5px;
      }

      @include break-max($break-lrg) {
        display: none;
      }
    }
  }

  .chat-header-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;

    i {
      color: $color-text-light;
      cursor: pointer;
      position: relative;
      z-index: 2;

      &:hover {
        color: $color-text;
      }
    }

    .mobile-mascot-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .mascot-image {
        border-radius: 50%;
        width: 36px;
        height: 36px;
      }

      h2 {
        margin: 5px 0 0;
        font-size: 16px;
      }

      @include break-min($break-lrg + 1) {
        display: none;
      }
    }

    .chat-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;

      .edit-title {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 400px;

        > input {
          outline: none;
          background: none;
          border: none;
          border-bottom: 1px solid $color-border;
          border-radius: 0;
          font-size: 16px;
          font-weight: $medium;
          letter-spacing: -0.38px;
          padding: 0;
          height: 28px;
          flex: 1;
          margin-right: 5px;
        }
      }

      h6 {
        max-width: 100%;
        margin: 0;
        font-weight: $medium;
        @include truncate();
      }

      .meta {
        display: block;
        margin-top: 2px;
      }

      @include break-max($break-lrg) {
        display: none;
      }
    }
  }

  .chat-header-right {
    margin: 0 15px 0 auto;
    position: relative;
    z-index: 2;

    .export-conversation,
    .tune-mascot {
      @include break-max($break-lrg) {
        display: none;
      }
    }
  }
}
