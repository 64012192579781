/*******************************
Popover
*******************************/

.popover {
  background-color: white;
  box-shadow: 0 7px 13px 0 rgba(black, 0.14);
  border: .5px solid $color-border;
  border-radius: 10px;
  cursor: initial;
  padding: 10px 17px;
  min-width: 220px;
  position: absolute;
  top: calc(100% + 5px);
  z-index: 999;
  animation: popoverTop 300ms;

  &.animate-left {
    animation: popoverLeft 300ms;
  }

  &.right {
    right: 0;
  }

  &.has-caret {
    &::after {
      background-color: white;
      border-top: .5px solid $color-border;
      border-left: .5px solid $color-border;
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      transform: rotate(45deg) translateX(-50%);
      position: absolute;
      left: 50%;
      top: -2px;
    }
  }

  .popover-top-actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;

    .icon-btn {
      margin: 0 0 0 7px;
    }
  }

  .popover-item {
    display: flex;
    align-items: center;
    color: $color-text;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    padding: 11px 0;

    > i {
      font-size: 16px;
      margin-right: 8px;
    }

    &:hover {
      text-decoration: none;
      color: $color-link;

      > i {
        color: inherit;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
  }
}

// Popover-specific overrides

.popover.inline-actions {
  top: auto;
  min-width: 150px;
  padding: 0;
  overflow: hidden;

  .popover-item {
    padding: 10px 15px;

    &:hover {
      background-color: $color-off-white;
      color: $color-text;
    }
  }
}

.popover.status-popover {
  padding: 15px 17px;
  min-width: 240px;
  left: -110px;

  > p {
    margin: 0;
  }

  > button {
    margin: 8px 0 0 !important;
  }
}
