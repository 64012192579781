.mascot-wrapper.chrome-extension{
  .mascot-extension-bar {
    margin-top: 30px;
    padding: 0px 15px;

    select {
      margin-bottom: 0px;
      height: 30px;
      padding: 5px;
    }
    button {
      margin-right: 10px;
    }
  }
}